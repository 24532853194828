import unescape from "lodash/unescape";


export const changeIframe = (action, properties) => {
  const dataToSend = {
    messageOrigin: "vidjet",
    origin: action,
    properties: properties,
  };
  window.parent.postMessage(dataToSend, "*");
}

export const displayCurrency = (currency, price) => {
  if (!currency || currency === "USD") {
    return `$ ${price}`;
  } else if (currency === "EUR") {
    return `${price} €`;
  } else if (currency === "NOK") {
    return `Kr ${price}`
  } else if (currency === "GBP") {
    return `£ ${price}`
  } else {
    return `${price} ${currency}`;
  }
};


export const decodeText = (text) => {
  const title = unescape(text);
  return title.replaceAll("&#34;", '"');
}

export const deepClone = obj => {
  if (obj === null) return null;
  if (typeof obj !== 'object') return obj;

  return Array.isArray(obj)
    ? obj.map(item => deepClone(item))
    : Object.entries(obj).reduce((copy, [key, value]) => ({ ...copy, [key]: deepClone(value) }), {});
}

const compareUrl = (url, toCompareToString) => {
  if (url.comparison === "eq" && url.string === toCompareToString) { return true }
  else if (url.comparison === "not_eq" && url.string !== toCompareToString) { return true }
  else if (url.comparison === "contains" && toCompareToString.includes(url.string)) { return true }
  else if (url.comparison === "not_contains" && !toCompareToString.includes(url.string)) { return true }

  return false
}

export const matchUrlSegmentation = (filters, url, type) => {
  //If segmentation is not set, do not match the segmentation
  if (!filters || !filters.length)
    return false

  for (const filter of filters) {
    if (!filter.type && !type) {
      return compareUrl(filter, url)
    }

    if (filter.type) {
      let matchBlock = matchUrlSegmentation(filter.filters, url, filter.type)

      if (!type) {
        return matchBlock;
      }

      if (type === 'or' && matchBlock) return true
      if (type === 'and' && !matchBlock) return false
    } else {
      let matchUrl = compareUrl(filter, url)

      if (type === 'or' && matchUrl) return true
      if (type === 'and' && !matchUrl) return false
    }
  }

  //If the main type is OR, if one URL matched true would be returned before so we have to return false when the check is completed
  //If the main type is AND, if one URL did not match false would be returned before so we have to return true when the check is completed
  return type === 'or' ? false : true
}

export const matchProductIdSegmentation = (productIds, pageProductId) => {
  if (!pageProductId) return false
  
  return productIds.includes(pageProductId.toString()) ||
    productIds.includes(parseInt(pageProductId))
}


